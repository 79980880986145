<template>
  <div v-if="commonCodeByOrder && gameCount" class="mainBg">
    <div class="mainBgdark">
      <section class="mt125 w100w h270 dp-f-c">
        <img src="../assets/img/main_ban.png">
        <!-- <div class="loading">
          <img src="@/assets/img/loading.gif" />
        </div> -->
        <!-- <transition name="fade">
          <ui-swiper />
        </transition> -->
        <template v-for="item in commonCodeByOrder['casino']" v-bind:key="item.vendorKey">
          <!-- <img src="../assets/img/main_ban.png" @click="onCasinoSelectGame(item.groupCode, item.code)"> -->
        </template>
      </section>

      <!-- <h2 class="game_name">
        <img src="@/assets/img/icon_live_name.svg" />
        <span>라이브카지노</span>
      </h2>
      <div class="wrapper">
        <a class="btn-game left" @click="scroll_left"></a>
        <div class="wrapper-box boxa">
            <div class="box">
              <template v-for="item in commonCodeByOrder['casino']" v-bind:key="item.vendorKey">
                <div class="game"
                    :style="getBackgroundImage(item)"
                    v-if="item.groupCodeName.indexOf('HC-') == -1">
                    <a @click="onCasinoSelectGame(item.groupCode, item.code)">
                        <p class="name">
                        {{$i18n.locale !== 'ko' ? item[`codeName${$i18n.locale.toUpperCase()}`] : item.codeName}}
                        </p>
                    </a>
                </div>
              </template>
            </div>
        </div>
        <a class="btn-game right" @click="scroll_right"></a>
      </div>
      <h2 class="game_name">
        <img src="@/assets/img/icon_slot_name.svg" />
        <span>슬롯게임</span>
      </h2>
      <div class="wrapper">
        <a class="btn-game left" @click="scroll_lefta"></a>
        <div class="wrapper-box boxb">
            <div class="box">
              <template v-for="item in commonCodeByOrder['slot']" v-bind:key="item.vendorKey">
                  <div class="game"
                    :style="getBackgroundImage(item)"
                      v-if="item.groupCodeName.indexOf('H-') === -1">
                      <a @click="slotOpen('isSlot', 'slot', item.codeName, item.code)">
                        <p class="name">
                          {{$i18n.locale !== 'ko' ? item[`codeName${$i18n.locale.toUpperCase()}`] : item.codeName}}
                        </p>
                      </a>
                  </div>
              </template>
            </div>
        </div>
        <a class="btn-game right" @click="scroll_righta"></a>
      </div>
      <h2 class="game_name" v-if="gameCount['hc-casino']">
        <img src="@/assets/img/icon_live_name.svg" />
        <span>호텔카지노</span>
      </h2>
      <div class="wrapper" v-if="gameCount['hc-casino']">
        <a class="btn-game left" @click="scroll_leftb"></a>
        <div class="wrapper-box boxc">
            <div class="box">
              <template v-for="item in commonCodeByOrder['casino']" v-bind:key="item.vendorKey">
                <div class="game"
                    :style="getBackgroundImage(item)"
                    v-if="item.groupCodeName.indexOf('HC-') > -1">
                    <a @click="onCasinoSelectGame(item.groupCode, item.code)">
                        <p class="name">
                        {{$i18n.locale !== 'ko' ? item[`codeName${$i18n.locale.toUpperCase()}`] : item.codeName}}
                        </p>
                    </a>
                </div>
              </template>
            </div>
        </div>
        <a class="btn-game right" @click="scroll_rightb"></a>
      </div>

      <h2 class="game_name" v-if="gameCount['mini-game']">
        <img src="@/assets/img/icon_live_name.svg" />
        <span>미니게임</span>
      </h2>
      <div class="wrapper" v-if="gameCount['mini-game']">
        <a class="btn-game left" @click="scroll_leftc"></a>
        <div class="wrapper-box boxd">
            <div class="box">
              <template v-for="item in commonCodeByOrder['minigame']" v-bind:key="item.vendorKey">
                <div class="game"
                    :style="getBackgroundImage(item)">
                    <a @click="onCasinoSelectGame(item.groupCode, item.code)">
                        <p class="name">
                        {{$i18n.locale !== 'ko' ? item[`codeName${$i18n.locale.toUpperCase()}`] : item.codeName}}
                        </p>
                    </a>
                </div>
              </template>
            </div>
        </div>
        <a class="btn-game right" @click="scroll_rightc"></a>
      </div>

      <h2 class="game_name" v-if="gameCount['sports']">
        <img src="@/assets/img/icon_live_name.svg" />
        <span>스포츠</span>
      </h2>
      <div class="wrapper" v-if="gameCount['sports']">
        <a class="btn-game left" @click="scroll_leftd"></a>
        <div class="wrapper-box boxe">
            <div class="box">
              <template v-for="item in commonCodeByOrder['sport']" v-bind:key="item.vendorKey">
                <div class="game"
                    :style="getBackgroundImage(item)">
                    <a @click="onCasinoSelectGame(item.groupCode, item.code)">
                        <p class="name">
                        {{$i18n.locale !== 'ko' ? item[`codeName${$i18n.locale.toUpperCase()}`] : item.codeName}}
                        </p>
                    </a>
                </div>
              </template>
            </div>
        </div>
        <a class="btn-game right" @click="scroll_rightd"></a>
      </div> -->

      <div class="tabWrap">
          <div class="tab_01">
            <ul class="">
              <!-- <li class="" data-tab="tab-4" v-if="gameCount['sports']" @click="currentTab = 'tab-4'" :class="{'current': currentTab == 'tab-4'}">
                  <h3>{{ $t('front.gnb.sport') }}</h3>
               </li> -->
               <li class="casino" data-tab="tab-1" v-if="gameCount['casino']" @click="currentTab = 'tab-1'" :class="{'current': currentTab == 'tab-1'}">
                  <h3>{{ $t('front.gnb.livecasino') }}</h3>
               </li>
               <li class="" data-tab="tab-2" v-if="gameCount['hc-casino']" @click="currentTab = 'tab-2'" :class="{'current': currentTab == 'tab-2'}">
                  <h3>{{ $t('front.gnb.hotelcasino') }}</h3>
               </li>
               <li class="slot1" data-tab="tab-3" v-if="gameCount['slot']" @click="currentTab = 'tab-3'" :class="{'current': currentTab == 'tab-3'}">
                  <h3>{{ $t('front.gnb.slotgame') }}</h3>
               </li>
               <li class="" data-tab="tab-6" v-if="gameCount['h-slot']" @click="currentTab = 'tab-6'" :class="{'current': currentTab == 'tab-6'}">
                  <h3>{{ $t('front.gnb.hotelslot') }}</h3>
               </li>
               <!-- <li class="" data-tab="tab-5" v-if="gameCount['mini-game']" @click="currentTab = 'tab-5'" :class="{'current': currentTab == 'tab-5'}">
                  <h3>{{ $t('front.gnb.minigame') }}</h3>
               </li> -->
            </ul>
          </div>
       </div>
      <!-- <div class="obj1 obj"><img src="../assets/img/obj1.png"></div> -->
       <section class="mt50 mb50 overflow-v">
         <div id="tab-1" :key="'tab-1'" class="tab-content" v-if="currentTab == 'tab-1'">
           <ul class="betGames betGamesa">
             <template v-for="item in commonCodeByOrder['casino']" v-bind:key="item.vendorKey">
               <li v-if="item.groupCodeName.indexOf('HC-') == -1"
                  @click="onCasinoSelectGame(item.groupCode, item.code)"
               >
                  <div :style="getBackgroundImage(item)" class="gameImg">
                    <em class="glogo" :style="getBackgroundLogoImage(item)"></em>
                  </div>
                  <p class="name">
                    <span>{{$i18n.locale !== 'ko' ? item[`codeName${$i18n.locale.toUpperCase()}`] : item.codeName}}</span>
                    <span class="gameNameEN">{{item.codeNameEN}}</span>
                    <button class="enterGame">게임입장</button>
                  </p>
               </li>
             </template>
           </ul>
         </div><!-- tab-1 -->

         <div id="tab-2" :key="'tab-2'" class="tab-content" v-if="currentTab == 'tab-2'"><!-- 호텔카지노 -->
           <ul class="betGames betGamesb">
             <template v-for="item in commonCodeByOrder['casino']" v-bind:key="item.vendorKey">
               <li v-if="item.groupCodeName.indexOf('HC-') > -1"
                   @click="onCasinoSelectGame(item.groupCode, item.code)"
               >
                  <div :style="getBackgroundImage(item)" class="gameImg">
                    <em class="glogo" :style="getBackgroundLogoImage(item)"></em>
                  </div>
                  <p class="name">
                    <span>{{$i18n.locale !== 'ko' ? item[`codeName${$i18n.locale.toUpperCase()}`] : item.codeName}}</span>
                    <span class="gameNameEN">{{item.codeNameEN}}</span>
                    <button class="enterGame">게임입장</button>
                  </p>
               </li>
             </template>
           </ul>
         </div>

         <div id="tab-3" :key="'tab-3'" class="tab-content" v-if="currentTab == 'tab-3'">
           <ul class="betGames betGamesc">
             <template v-for="item in commonCodeByOrder['slot']" v-bind:key="item.vendorKey">
               <li v-if="item.groupCodeName.indexOf('H-') === -1"
                   @click="slotOpen('isSlot', 'slot', item.codeName, item.code)"
               >
                  <div :style="getBackgroundImage(item)" class="gameImg">
                    <em class="glogo" :style="getBackgroundLogoImage(item)"></em>
                  </div>
                  <p class="name">
                    <span>{{$i18n.locale !== 'ko' ? item[`codeName${$i18n.locale.toUpperCase()}`] : item.codeName}}</span>
                    <span class="gameNameEN">{{item.codeNameEN}}</span>
                    <button class="enterGame">게임입장</button>
                  </p>
               </li>
             </template>
           </ul>
         </div><!-- tab-3 -->

         <div id="tab-6" :key="'tab-6'" class="tab-content" v-if="currentTab == 'tab-6'">
          <ul class="betGames betGamesa">
            <template v-for="item in commonCodeByOrder['slot']" v-bind:key="item.vendorKey">
              <li :style="getBackgroundImage(item)"
                   v-if="item.groupCodeName.indexOf('H-') > -1"
                   @click="slotOpen('isSlot', 'slot', item.codeName, item.code)"
               >
                  <div :style="getBackgroundImage(item)" class="gameImg">
                    <em class="glogo" :style="getBackgroundLogoImage(item)"></em>
                  </div>
                  <p class="name">
                    <span>{{$i18n.locale !== 'ko' ? item[`codeName${$i18n.locale.toUpperCase()}`] : item.codeName}}</span>
                    <span class="gameNameEN">{{item.codeNameEN}}</span>
                    <button class="enterGame">게임입장</button>
                  </p>
               </li>
             </template>
           </ul>
         </div><!-- tab-2 -->

         <div id="tab-4" :key="'tab-4'" class="tab-content" v-if="currentTab == 'tab-4'">
           <ul class="betGames betGamesa">
             <template v-for="item in commonCodeByOrder['sport']" v-bind:key="item.vendorKey">
               <li
                   @click="onCasinoSelectGame(item.groupCode, item.code)"
                   @mouseover="item.isHover = true"
                   @mouseout="item.isHover = false"
               >
                  <div :style="getBackgroundImage(item)" class="gameImg">
                    <em class="glogo" :style="getBackgroundLogoImage(item)"></em>
                  </div>
                  <p class="name">
                    <span>{{$i18n.locale !== 'ko' ? item[`codeName${$i18n.locale.toUpperCase()}`] : item.codeName}}</span>
                    <span class="gameNameEN">{{item.codeNameEN}}</span>
                    <!-- <button class="enterGame" @click="onCasinoSelectGame(item.groupCode, item.code)">게임입장</button> -->
                  </p>
               </li>
             </template>

           </ul>
         </div><!-- tab-4 -->

         <div id="tab-5" :key="'tab-5'" class="tab-content" v-if="currentTab == 'tab-5'">
           <ul class="betGames betGamesa">

             <template v-for="item in commonCodeByOrder['minigame']" v-bind:key="item.vendorKey">
               <li
                   @click="onCasinoSelectGame(item.groupCode, item.code)"
                   @mouseover="item.isHover = true"
                   @mouseout="item.isHover = false"
               >
                  <div :style="getBackgroundImage(item)" class="gameImg">
                    <em class="glogo" :style="getBackgroundLogoImage(item)"></em>
                  </div>
                  <p class="name">
                    <span>{{$i18n.locale !== 'ko' ? item[`codeName${$i18n.locale.toUpperCase()}`] : item.codeName}}</span>
                    <span class="gameNameEN">{{item.codeNameEN}}</span>
                    <button class="enterGame">게임입장</button>
                  </p>
               </li>
             </template>
           </ul>
         </div><!-- tab-5 -->

       </section>

      <section>
        <div class="servicecenter">
          <ul>
            <li>
              <h4>{{ $t('front.common.notice') }}</h4>
              <a @click="goPageByName('notice')">+ MORE</a>
            </li>
            <template v-for="item in noticeList" :key="item">
              <li @click="onClickNoticeRead(item)" class="notice">
                <span>{{item.title}}</span>
                <span>{{item.regDate}}</span>
              </li>
            </template>
            <template v-for="item in listMaxCount - noticeList.length" :key="item">
              <li>
                <span>&nbsp;</span>
                <span>&nbsp;</span>
              </li>
            </template>
          </ul>
          <ul @click="goPageByName('charge')">
            <li>
              <h4>{{ $t('front.main.livecharge') }}</h4>
              <a>+ MORE</a>
            </li>
            <!-- <template v-for="item in cashInList" :key="item.memId">
              <li>
                <span>{{item.memId}}</span>
                <span><em class="c-rd">{{thousand(Number(item.cashAmt))}} 원</em>{{item.regDate}}</span>
              </li>
            </template>
            <template v-for="item in listMaxCount - cashInList.length" :key="item">
              <li>
                <span>&nbsp;</span>
                <span><em>&nbsp;</em>&nbsp;</span>
              </li>
            </template> -->
            <li>
              <span>ceg*****</span>
              <span><em>500,000 원</em>{{ formattedDate }}</span>
            </li>
            <li>
              <span>ceg*****</span>
              <span><em>300,000 원</em>{{ formattedDate }}</span>
            </li>
            <li>
              <span>ss0*****</span>
              <span><em>1,000,000 원</em>{{ formattedDate }}</span>
            </li>
            <li>
              <span>ceg*****</span>
              <span><em>300,000 원</em>{{ formattedDate }}</span>
            </li>
            <li>
              <span>qio*****</span>
              <span><em>490,000 원</em>{{ formattedDate }}</span>
            </li>
          </ul>
          <ul @click="goPageByName('exchange')">
            <li>
              <h4>{{ $t('front.main.liveExchange') }}</h4>
              <a>+ MORE</a>
            </li>
            <!-- <template v-for="item in cashOutList" :key="item.memId">
              <li>
                <span>{{item.memId}}</span>
                <span><em class="c-bl">{{thousand(Number(item.cashAmt*-1))}} 원</em>{{item.regDate}}</span>
              </li>
            </template>
            <template v-for="item in listMaxCount - cashOutList.length" :key="item">
              <li>
                <span>&nbsp;</span>
                <span><em>&nbsp;</em>&nbsp;</span>
              </li>
            </template> -->
            <li>
              <span>as1*****</span>
              <span><em>500,000 원</em>{{ formattedDate }}</span>
            </li>
            <li>
              <span>hel*********</span>
              <span><em>300,000 원</em>{{ formattedDate }}</span>
            </li>
            <li>
              <span>vip*****</span>
              <span><em>200,000 원</em>{{ formattedDate }}</span>
            </li>
            <li>
              <span>nsh*****</span>
              <span><em>2,050,000 원</em>{{ formattedDate }}</span>
            </li>
            <li>
              <span>coc*****</span>
              <span><em>250,000 원</em>{{ formattedDate }}</span>
            </li>
          </ul>
        </div>
      </section>
      <ul class="calogo">
        <li><img src="@/assets/img/ca_logo1.png" /></li>
        <li><img src="@/assets/img/ca_logo2.png" /></li>
        <li><img src="@/assets/img/ca_logo3.png" /></li>
        <li><img src="@/assets/img/ca_logo4.png" /></li>
        <li><img src="@/assets/img/ca_logo5.png" /></li>
        <li><img src="@/assets/img/ca_logo6.png" /></li>
        <li><img src="@/assets/img/ca_logo7.png" /></li>
        <li><img src="@/assets/img/ca_logo8.png" /></li>
        <li><img src="@/assets/img/ca_logo9.png" /></li>
        <li><img src="@/assets/img/ca_logo10.png" /></li>
      </ul>
      <div class="mainPopWrap">
        <template v-for="(item) in popupList" v-bind:key="item.bannerIdx">
          <div class="mainPop" v-if="item.delYn == 'N' && !item.isClose" :data-idx="item.bannerIdx">
            <div class="closeWrap">
              <a class="close" @click="popupCloseByCookie(item)">{{$t('front.main.today')}}</a>
              <a class="close" @click="popupClose(item)">{{$t('front.main.close')}}</a>
            </div>
            <img :src="item.bannerImg" />
          </div>
        </template>
      </div>
    </div>
  </div>

   <!--div class="loginmodal">
      <div class="mloading-container">
         <div class="mloading"></div>
         <div class="mloading-text">loading</div>
      </div>
   </div-->
</template>

<script>

import '@/styles/common.css'
import { getPopupCookie, setPopupCookie, tokenCheck } from '@/libs/auth-helper'

import { getMain, getMainCashStatus, getMainNotice } from '@/api/main'
import { mapState } from 'vuex'
// import UiSwiper from '@/components/ui/UiSwiper.vue'
import store from '@/store'
import { thousand } from '../libs/utils'

export default {
  name: 'Index.vue',
  components: {
  },
  async created () {
    // this.emitter.emit('Loading', true)
    // this.emitter.emit('Loading', true)
    // setTimeout(() => {
    //
    // }, 5000)
    // setTimeout(() => {
    //   this.emitter.emit('Loading', false)
    // }, 8000)
    this.loadMain()
    this.popupMerge()
    this.loadNotice()
    await this.loadCashStatus()
  },
  data () {
    return {
      currentTab: 'tab-1',
      popupList: {},
      cashInList: [],
      cashOutList: [],
      noticeList: [],
      listMaxCount: 5,
      noticeListMaxCount: 5,
      swiperView: false,
      todayDate: ''
    }
  },
  watch: {
    gameCount () {
      if (this.gameCount && this.commonCodeByOrder && !this.swiperView) {
        this.loadSwiper()
      }
    },
    commonCodeByOrder () {
      if (this.gameCount && this.commonCodeByOrder && !this.swiperView) {
        this.loadSwiper()
      }
    },
    popup () {
      this.popupMerge()
    },
    popupLogin () {
      this.popupMerge()
    },
    async userData () {
      await store.dispatch('storePopup')
      if (this.userData) {
        await store.dispatch('storePopupLogin')
      }
      await this.popupMerge()
    }
  },
  mounted () {
    this.setToday()
  },
  computed: {
    ...mapState([
      'userData',
      'commonCode',
      'commonCodeByOrder',
      'popup',
      'popupLogin',
      'gameCount'
    ]),
    formattedDate () {
      const year = this.todayDate.getFullYear()
      const month = (this.todayDate.getMonth() + 1).toString().padStart(2, '0')
      const day = this.todayDate.getDate().toString().padStart(2, '0')
      return `${year}-${month}-${day}`
    }

  },
  methods: {
    setToday () {
      this.todayDate = new Date()
    },
    scroll_left () {
      const content = document.querySelector('.boxa')
      content.scrollLeft -= 720
    },
    scroll_right () {
      const content = document.querySelector('.boxa')
      content.scrollLeft += 720
    },
    scroll_lefta () {
      const content = document.querySelector('.boxb')
      content.scrollLeft -= 720
    },
    scroll_righta () {
      const content = document.querySelector('.boxb')
      content.scrollLeft += 720
    },
    scroll_leftb () {
      const content = document.querySelector('.boxc')
      content.scrollLeft -= 720
    },
    scroll_rightb () {
      const content = document.querySelector('.boxc')
      content.scrollLeft += 720
    },
    scroll_leftc () {
      const content = document.querySelector('.boxd')
      content.scrollLeft -= 720
    },
    scroll_rightc () {
      const content = document.querySelector('.boxd')
      content.scrollLeft += 720
    },
    scroll_leftd () {
      const content = document.querySelector('.boxe')
      content.scrollLeft -= 720
    },
    scroll_rightd () {
      const content = document.querySelector('.boxe')
      content.scrollLeft += 720
    },
    loadSwiper () {
      setTimeout(() => {
        this.gameSwiper()
        this.gameSwipera()
        this.gameSwiperb()
        this.swiperView = true
      }, 0)
    },
    gameSwiper () {
      // eslint-disable-next-line no-undef,no-new
      new Swiper('.gameSlide', {
        spaceBetween: 20,
        slidesPerView: 5,
        centeredSlides: false,
        slidesPerGroupSkip: 5,
        slidesPerGroup: 5,
        grabCursor: true,
        keyboard: {
          enabled: true
        },
        navigation: {
          nextEl: '.next1',
          prevEl: '.prev1'
        }
      })
    },
    gameSwipera () {
      // eslint-disable-next-line no-undef,no-new
      new Swiper('.gameSlidea', {
        spaceBetween: 20,
        slidesPerView: 5,
        centeredSlides: false,
        slidesPerGroupSkip: 5,
        slidesPerGroup: 5,
        grabCursor: true,
        keyboard: {
          enabled: true
        },
        navigation: {
          nextEl: '.next2',
          prevEl: '.prev2'
        }
      })
    },
    gameSwiperb () {
      // eslint-disable-next-line no-undef,no-new
      new Swiper('.gameSlideb', {
        spaceBetween: 20,
        slidesPerView: 5,
        centeredSlides: false,
        slidesPerGroupSkip: 5,
        slidesPerGroup: 5,
        grabCursor: true,
        keyboard: {
          enabled: true
        },
        navigation: {
          nextEl: '.next3',
          prevEl: '.prev3'
        }
      })
    },
    onClickNoticeRead (item) {
      console.log(item)
      this.$router.push({ name: 'noticeRead', params: { boardIdx: item.boardIdx } })
    },
    thousand,
    async loadCashStatus () {
      await getMainCashStatus({}).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          console.log(result.data)
          this.cashInList = result.data.cashInList.slice(0, this.listMaxCount)
          this.cashOutList = result.data.cashOutList.slice(0, this.listMaxCount)
        }
      })
    },
    loadNotice () {
      getMainNotice({}).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          console.log(result)
          this.noticeList = result.data.boardList.slice(0, this.noticeListMaxCount)
        }
      })
    },
    getBackgroundImage (item) {
      try {
        const image = require('../assets/img/game_main_pc_' + item.code + '_off.png')
        const imageOff = require('../assets/img/game_main_pc_' + item.code + '_off.png')
        if (image && imageOff) {
          if (item.isHover) {
            return { backgroundImage: 'url(' + image + ')' }
          } else {
            return { backgroundImage: 'url(' + imageOff + ')' }
          }
        } else {
          return { backgroundImage: 'url()' }
        }
      } catch (e) {
        return { backgroundImage: 'url()' }
      }
    },
    getBackgroundLogoImage (item) {
      try {
        const image = require('../assets/img/glogo' + item.code + '.png')
        return { backgroundImage: 'url(' + image + ')' }
      } catch (e) {
        return { backgroundImage: 'url()' }
      }
    },
    popupMerge () {
      const popupLogin = this.userData ? this.popupLogin : []
      const pList = this.popup.concat(popupLogin)

      this.popupList = {}

      pList.forEach(item => {
        if (!item.isClose) {
          const key = item.bannerIdx
          const siteId = process.env.VUE_APP_SITE_ID || 'moka'
          const name = siteId + '_popup_' + item.bannerIdx
          const cookie = getPopupCookie(name)
          if (cookie) {
            item.isClose = true
          } else {
            item.isClose = false
          }
          if (!this.popupList[key]) {
            this.popupList[key] = item
          }
        }
      })
    },
    popupClose (item) {
      item.isClose = true
    },
    popupCloseByCookie (item) {
      item.isClose = true
      setPopupCookie(item)
    },
    slotOpen (event, groupCode, codeName, code) {
      this.emitter.emit(event, { groupCode, codeName, code })
      this.emitter.emit('Loading', false)
    },
    loadMain () {
      if (this.userData) {
        getMain({}).then(res => {
          // console.log(res)
        })
      }
    }
  },
  async beforeRouteEnter (to, from, next) {
    await tokenCheck()
    // if (result) {
    next()
    // }
  }
}
</script>
<style scoped>
.mt111 {height: 400px}
.loading {z-index: 10;position: absolute;top: 0;width: 100%;height: 310px;background: #;padding-top: 40px;display: flex;align-items: center;justify-content: center;}
.loading img {height: 100%;}
h2.mainnames {width: 1316px;margin: 0 auto;padding: 30px 0 20px;font-size: 30px;font-weight: bold;color: #000;}
ul.tabs {display: flex;justify-content: center;align-items: center;padding: 30px 0;width: 1316px;margin: 0 auto;gap: 5px;}
ul.tabs li {display: flex;align-items: center;justify-content: center;font-size: 14px;color: #fff;cursor: pointer;width: 148px;height: 30px;border: solid 1px #777;background: #111;}
.tab-content{width: 1316px;margin: 0 auto;}
.tab-content.current{display: inherit;}
ul.tabs li:hover {border: 1px solid #e5972d;background: #333;}
ul.tabs li.current {color: #000;border: 1px solid #e5972d;background: #e5972d;}

.slotmodalwrap::-webkit-scrollbar {width: 10px;}
.slotmodalwrap::-webkit-scrollbar-thumb {background-color: none;border-radius: 10px;background-clip: padding-box;border: 2px solid transparent;}
.slotmodalwrap::-webkit-scrollbar-track {background-color: none;border-radius: 10px;}
.slotmodal {background: #ededed;border: 1px solid #707070;border-radius: 30px;position: absolute;left: 50%;top: 50%; transform: translate(-50%,-50%);z-index: 1;width: 1314px;}
.slotmodal>h3 {width: 100%;position: relative;text-align: center;font-size: 30px;font-weight: bold;color: #fff;padding: 15px 0;background: #222;border-radius: 30px 30px 0 0;}
.slotmodal>h3::after {content: '';display: block;width: 100%;height: 17px;background-image: linear-gradient(to right, #319e8a, #1c594e);position: absolute;bottom: -25px;}
.slotmodal>h3 .close {position: absolute;right: 15px;top: 9px;height: 40px;}
.slotmodalwrap {max-height: 900px;overflow-y: auto;padding-top: 55px;}
.slotmodalwrap ul {padding: 0 65px 30px;display: flex;gap: 25px;justify-content: center;}
.slotmodalwrap ul li {transition: all ease 1s;cursor: pointer;}
.slotmodalwrap ul li:hover, .slotmodal ul li:focus, .slotmodal ul li:active {transform: scale(1.1);}
.slotmodalwrap ul li img {width: 100%;border-radius: 12px;border: 2px solid #319e8a;height: 200px;}

.mainPopWrap {display: flex;align-items: center;gap: 50px;position: fixed;top: 50%;left: 50%;transform: translate(-50%, -50%);z-index: 100;}
.mainPop {position: relative;min-width: 320px;background: #fff;border: 1px solid #1c594e;z-index: 100;}
.mainPop .closeWrap {width: calc(100% - 30px);display: flex;align-items: center;justify-content: end;gap: 15px;position: absolute;bottom: 0;padding: 15px;background: rgba(0,0,0,.7);color: #fff;font-size: 12px;}
.mainPop a.close {color: #fff;}
.mainPop>img {width: 430px;height: 640px;vertical-align: bottom;object-fit: cover;object-position: center;}
</style>
<style scoped src="@/styles/header.css"></style>
<style scoped src="@/styles/footer.css"></style>
<style scoped src="@/styles/main.css"></style>
<style scoped src="@/styles/swiper.min.css"></style>
